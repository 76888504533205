@import "../../themes/generated/variables.base.scss";

.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .main-heading {
        font-size: 2rem;
        color: #2d3748;
        margin: 1.5rem 0;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
    }

    .content-block {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
    }

    .dx-card {
        max-width: 1000px;
        width: 100%;
        padding: 40px;
        border-radius: 16px;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
        background: white;
        text-align: center;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hero-text {
        width: 100%;
        max-width: 800px;
        margin-top: -24px;
        margin-bottom: 24px;
        line-height: 1.6;
        color: #4a5568;
        font-size: 1.1rem;
        text-align: center;
    }

    .store-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: 32px 32px;
        width: 100%;

        img {
            transition: transform 0.2s;
            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        margin: 32px 32px;

    }

    .dx-button {
        min-width: unset;
        margin: 0;
        border-radius: 8px;
        transition: all 0.2s;

        &:hover {
            transform: translateY(-2px);
        }
    }

    .dx-button-text {
        font-size: 16px;
        font-weight: 500;
    }

    #logo {
        font-size: 42px;
        margin-bottom: 20px;
        text-align: center;
    }

    .credit {
        padding: 20px;
        text-align: center;
        width: 100%;

        img {
            opacity: 0.7;
            transition: opacity 0.2s;
            
            &:hover {
                opacity: 1;
            }
        }
    }
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}